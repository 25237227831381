export const TERM_LENGTH_FIELD = "term_length";
export const FUNDING_COMMISSION_FIELD = "funding_commission";
export const TOTAL_INITIAL_PAYMENT_FIELD = "total_initial_payment";

const OFFER_FIELDS = {
  term_length: TERM_LENGTH_FIELD,
  funding_commission: FUNDING_COMMISSION_FIELD,
  total_initial_payment: TOTAL_INITIAL_PAYMENT_FIELD
};

export default OFFER_FIELDS;
