<template>
  <offer-details-modal
    v-if="showOfferDetailsModal"
    :placement-id="model.placement_id"
    :product-type="model.product_type"
    :close="() => (showOfferDetailsModal = false)"
  />
  <template v-if="showButton">
    <button
      v-if="model.offers_count"
      v-tooltip="$t('DEALS.DEAL_PROGRESS.ADD_OFFER')"
      class="border-2 border-blue-500 rounded-full px-0.5 flex justify-center items-center font-semibold text-xl text-blue-500 leading-none"
      @click.stop="showOfferDetailsModal = true"
    >
      <span class="-mt-0.5">+</span>
    </button>
    <primary-button
      v-else
      :disabled="offersLoading"
      @click.stop="showOfferDetailsModal = true"
    >
      {{ $t("DEALS.DEAL_PROGRESS.CREATE_OFFER") }}
    </primary-button>
  </template>
</template>
<script setup lang="ts">
import type { ILenderPlacement, IPlacementStatuses } from "@/models/funders";
import type { PropType } from "vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import OfferDetailsModal from "@/views/deals/modals/OfferDetailsModal.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";

const props = defineProps({
  model: {
    type: Object as PropType<ILenderPlacement & { product_type: string }>,
    required: true
  }
});

const { getters } = useStore();
const showOfferDetailsModal = ref(false);

const statuses = computed<IPlacementStatuses>(
  () => getters["options/placementStatuses"]
);
const offersLoading = computed<boolean>(
  () => getters["applications/offersLoading"]
);

const showButton = computed(() => {
  const isApproved =
    props.model.status ===
    Number(
      Object.keys(statuses.value).find(
        (key) => statuses.value[key as keyof IPlacementStatuses] === "Approved"
      ) || 0
    );

  return isApproved;
});
</script>
